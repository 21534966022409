import React, { useState } from "react";
import "./InvoicePopUp.css";
import IconContainer from "../iconContainer/iconContainer";
import { ReactComponent as EditSVG } from "../../../assets/images/edit.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/closeSVG.svg";
import loaderSvg from "../../../assets/images/loader.svg";
import CheckBox from "../checkBox/checkBox";
import moment from "moment";
import { site } from "../../../@types/sites";
import { companyType, personType } from "../../../pages/WasteAcceptance/@types";
import { wasteItem } from "../../../pages/WasteAcceptance/defaultData";
import {
  docNumTransform,
  isDigit,
  isLTNum,
  LT_Months,
  numToText,
  resolve_unit_price,
  serijaNumTransform,
  transform,
} from "../../../helpers/misc";
import { isEmail } from "../../../helpers/validate";
import floatInfo from "../floatInfo/floatInfo";
import useTranslate from "../../../translate/useTranslate";

const split = (text: string) => {
  //Split text based on line spaces
  return text.split(/(?:\r\n|\n)/g);
};

const getTotal = (wasteItems: wasteItem[], vat?: boolean): number => {
  let num = 0;
  try {
    wasteItems.forEach(
      (wst) =>
        (num =
          num +
          (vat ? wst.paidPriceVat_raw : wst.paidPrice_raw) * wst.paidQuantity)
    );
    return parseFloat(num.toFixed(2));
  } catch (e: any) {
    return num;
  }
};

const getTotalPVM = (wasteItems: wasteItem[]): number => {
  let num = 0;
  try {
    num = getTotal(wasteItems, true);
    return parseFloat(num.toFixed(2));
  } catch (e: any) {
    return num;
  }
};

const dateWords = (date?: string): string => {
  try {
    if (date) {
      const newDate = new Date(date);

      if (newDate.getTime() !== newDate.getTime()) {
        throw `Invalid date!`;
      }

      const year = newDate.getFullYear();
      const month = newDate.getMonth();
      const day = newDate.getDate();
      return `${year} m. ${LT_Months[month]} ${day} d.`;
    } else {
      const newDate = new Date();

      const year = newDate.getFullYear();
      const month = newDate.getMonth();
      const day = newDate.getDate();
      return `${year} m. ${LT_Months[month]} ${day} d.`;
    }
  } catch (err) {
    const newDate = new Date();

    const year = newDate.getFullYear();
    const month = newDate.getMonth();
    const day = newDate.getDate();
    return `${year} m. ${LT_Months[month]} ${day} d.`;
  }
};

const totalWords = (value: number) => {
  value = parseFloat(`${value}`) || 0; // Incase a non number is passed, return zero
  const decim = (Number((value % 1).toFixed(2)) || 0) * 100;
  const valueInt = parseInt(`${value}`);
  return `${numToText(valueInt)} eurai ${decim.toFixed(0)} ct.`;
};

const InvoicePopUp = ({
  loading,
  wasteItems,
  details,
  setDetails,
  isCompany,
  currentSite,
  handlePayButtonClick,
  closePopUp,
}: {
  loading: boolean;
  wasteItems: wasteItem[];
  currentSite: site;
  isCompany: boolean;
  details: personType | companyType;
  setDetails: (data: personType | companyType) => void;
  handlePayButtonClick: (sendInvoice: boolean) => void;
  closePopUp: () => void;
}) => {
  return (
    <div className={`declaration_wrap`}>
      <div className="declaration_float" onClick={closePopUp}></div>
      <InvoicePopUpContainer
        loading={loading}
        wasteItems={wasteItems}
        currentSite={currentSite}
        isCompany={isCompany}
        details={details}
        setDetails={setDetails}
        handlePayButtonClick={handlePayButtonClick}
        closePopUp={closePopUp}
        readOnly={false}
      />
    </div>
  );
};

export default InvoicePopUp;

export const InvoicePopUpContainer = ({
  loading,
  wasteItems,
  details,
  setDetails,
  isCompany,
  currentSite,
  handlePayButtonClick,
  closePopUp,
  readOnly,
  acceptanceId,
  date,
  serija,
  serija_doc_nr,
}:
  | {
      loading: boolean;
      wasteItems: wasteItem[];
      currentSite: site;
      isCompany: boolean;
      details: personType | companyType;
      setDetails: (data: personType | companyType) => void;
      handlePayButtonClick: (sendInvoice: boolean) => void;
      closePopUp: () => void;
      readOnly: false;
      acceptanceId?: never;
      date?: never;
      serija?: never;
      serija_doc_nr?: never;
    }
  | {
      loading?: never;
      wasteItems: wasteItem[];
      isCompany: boolean;
      details: Omit<personType | companyType, "signature">;
      readOnly: true;
      acceptanceId: number;
      serija_doc_nr: number | null;
      date: string;
      serija: string;
      currentSite?: never;
      setDetails?: never;
      handlePayButtonClick?: never;
      closePopUp?: never;
    }) => {
  const { t } = useTranslate();

  const invoicHeaders = [
    {
      serialNo: t("Serial No."),
      wasteCode: t("Code"),
      wasteName: t("Description"),
      contractNo: t("Contract no."),
      measure: t("Quantity"),
      wasteUnit: t("Unit of Measurement"),
      pricePerUnit: t("Amount, EUR"),
      totalSum: t("Amount, EUR"),
    },
  ];
  const companyDetails = details as companyType;
  const personDetails = details as personType;

  const set = (obj: Partial<personType> | Partial<companyType>) => {
    const Details = { ...details, ...obj } as personType | companyType;
    if (setDetails) {
      setDetails({ ...Details });
    }
  };

  const defAddress = details.address;
  const defEmail = details.email;
  const defPVM = isCompany ? companyDetails.vatcode : "";

  const [address, setAddress] = useState(defAddress || "");
  const [addressFocus, setAdressFocus] = useState(false);
  const [email, setEmail] = useState(defEmail || "");
  const [pvm, setPvm] = useState(defPVM || "");

  const [emailFocus, setEmailFocus] = useState(!defEmail);
  const [pvmFocus, setPvmFocus] = useState(false);

  const [emailCheck, setEmailCheck] = useState(false);

  return (
    <div className={`flex1 declaration_container fdCol declaration_scroller`}>
      <div className="invoice_popup_container invoice_scroller">
        {!readOnly && (
          <div
            style={{ top: `2.5rem`, right: `2.5rem` }}
            className="dec_popUpIcon_div center"
            onClick={closePopUp}
          >
            <CloseIcon />
          </div>
        )}
        <div className="invoice_popup_body">
          <div className="invoice_popup_title flex">
            <div className="invoice_popup_logo">
              <img
                src={require("../../../assets/images/plumwaste_invoice_logo.png")}
                alt=""
                className="img_div_contain"
              />
            </div>
            <div className="flex1">
              <h4>{t("VAT invoice")}</h4>
              <p>
                {serijaNumTransform(
                  {
                    acceptanceId,
                    serija,
                    serija_doc_nr: serija_doc_nr || undefined,
                  },
                  t
                )}
              </p>
              <p className="invoice_date">{dateWords(date)}</p>
            </div>
            <div className="invoice_popup_logo"></div>
          </div>
          <div className="invoice_popup_details_wrapper head">
            <div className="invoice_popup_details_container">
              <div className="head">{t("Seller")}</div>
              <p className="invoice_holder">Plum Waste, UAB</p>
              <p>{t("Address")}: Lvivo g. 89-75, LT-08104 Vilnius</p>
              <p>{t("Company code")}: 181705485</p>
              <p>{t("VAT code")}: LT100002064111</p>
            </div>
            <div className="invoice_popup_details_container">
              <div className="head">{t("Buyer")}</div>
              <p className="invoice_holder">
                {isCompany
                  ? `${companyDetails.companyName || ``}`
                  : `${personDetails.name || ``} ${
                      personDetails.surname || ``
                    }`}
              </p>
              <div className="invoice_popup_details_edit_wrap">
                <span>{t("Address")}: </span>

                {addressFocus && !readOnly ? (
                  <textarea
                    className="invoice_scroller flex1"
                    name=""
                    id=""
                    autoFocus={true}
                    onBlur={(e) => {
                      e.stopPropagation();
                      setAdressFocus(false);
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const address = value || null;
                      setAddress(value);
                      set({ address });
                    }}
                    value={address}
                  ></textarea>
                ) : (
                  <span className="flex1">
                    {split(address).map((text, n: number) =>
                      text ? (
                        <p key={`invoice_Adress_${n}`}>{text}</p>
                      ) : (
                        <p key={`invoice_Adress`}>&nbsp;</p>
                      )
                    )}
                  </span>
                )}
                {!readOnly && (
                  <IconContainer size="lg">
                    <EditSVG
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setAdressFocus(!addressFocus);
                      }}
                    />
                  </IconContainer>
                )}
              </div>
              {isCompany ? (
                <div>Imonės kodas: {`${companyDetails.companyCode || ``}`}</div>
              ) : (
                ``
              )}
              <div className="invoice_popup_details_edit_wrap mtS">
                <span>{t("VAT code")}: </span>
                {pvmFocus && !readOnly ? (
                  <input
                    className="invoice_scroller flex1"
                    autoFocus={true}
                    type="number"
                    onBlur={() => setPvmFocus(false)}
                    onChange={(e) => {
                      const vatcode = e.target.value;
                      if (isLTNum(vatcode) && isDigit(vatcode)) {
                        setPvm(vatcode);
                        if (vatcode) {
                          set({ vatcode: vatcode as unknown as number });
                        } else {
                          set({ vatcode: null });
                        }
                      }
                    }}
                    value={pvm}
                  />
                ) : (
                  <p className="flex1">{pvm}</p>
                )}
                {!readOnly && (
                  <IconContainer size="lg">
                    <EditSVG
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setPvmFocus(!pvmFocus);
                      }}
                    />
                  </IconContainer>
                )}
              </div>
              <div className="invoice_popup_details_edit_wrap email">
                {t("Email")}{" "}
                {emailFocus && !readOnly ? (
                  <input
                    className="invoice_scroller flex1"
                    autoFocus={true}
                    onBlur={() => setEmailFocus(false)}
                    onChange={(e) => {
                      const value = e.target.value;
                      const email = value || null;
                      setEmail(value);
                      set({ email });
                    }}
                    name=""
                    id=""
                    value={email}
                  />
                ) : (
                  <p className="flex1">{email}</p>
                )}
                {!readOnly && (
                  <IconContainer size="lg">
                    <EditSVG
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        setEmailFocus(!emailFocus);
                      }}
                    />
                  </IconContainer>
                )}
              </div>
            </div>
          </div>
          <div className="invoice_due_date">
            <b>{t("Invoice due")}:</b>
            {moment(date).format("YYYY-MM-DD")}
          </div>
          <div className="invoice_popup_table">
            {invoicHeaders.map((header, n: number) => (
              <div
                key={`invoiceHeader_${n}`}
                className="invoice_popup_table_wrapper"
              >
                <div className="invoice_popup_table_container">
                  <div>{header.serialNo}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.wasteCode}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.wasteName}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.contractNo}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.measure}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.wasteUnit}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.pricePerUnit}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{header.totalSum}</div>
                </div>
              </div>
            ))}
            {wasteItems.map((waste, index: number) => (
              <div
                key={`invoice_waste_${index}`}
                className="invoice_popup_table_wrapper"
              >
                <div className="invoice_popup_table_container">
                  <div>{index + 1}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{waste.code}</div>
                </div>
                <div className="invoice_popup_table_container wasteDesc">
                  <div>{waste.name}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>{companyDetails.contractNr || ``}</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>
                    {transform(
                      waste.paidQuantity.toFixed(
                        waste.paidQuantity % 1 === 0 ? 0 : 3
                      )
                    )}
                  </div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>kg</div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>
                    {transform(
                      resolve_unit_price(
                        waste.paidPrice_raw,
                        waste.paidQuantity
                      ).toFixed(2)
                    )}
                  </div>
                </div>
                <div className="invoice_popup_table_container">
                  <div>
                    {transform(
                      (waste.paidPrice_raw * waste.paidQuantity).toFixed(2)
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="invoice_total_container">
            <>
              <div className="invoice_total_label">
                {t("Amount without VAT")}
              </div>
              <div className="invoice_total_value">
                {transform(getTotal(wasteItems).toFixed(2))} EUR
              </div>
            </>
            <>
              <div className="invoice_total_label">{t("VAT sum")}</div>
              <div className="invoice_total_value">
                {transform(
                  (getTotalPVM(wasteItems) - getTotal(wasteItems)).toFixed(2)
                )}{" "}
                EUR
              </div>
            </>
            <>
              <div className="invoice_total_label head">
                {t("Total inc. VAT")}
              </div>
              <div className="invoice_total_value">
                {transform(getTotalPVM(wasteItems).toFixed(2))} EUR
              </div>
            </>
          </div>
          <div className="invoice_total_word_container">
            <b>{t("Total inc. VAT in words")}: </b>
            <span>
              {totalWords(parseFloat(getTotalPVM(wasteItems).toFixed(2)))}
            </span>
          </div>

          <div className="invoice_popup_details_wrapper extend">
            <div className="invoice_popup_details_container">
              <p>{t("Invoice issued by")}</p>
              <p className="split">&nbsp;</p>
              <p>{t("Position, name, surname, signature")}</p>
            </div>
            <div className="invoice_popup_details_container">
              <p>{t("Invoice received by")}</p>
              <p className="split">&nbsp;</p>
              <p>{t("Position, name, surname, signature")}</p>
            </div>
          </div>
          <div className="invoice_popup_footer_wrapper flex1">
            <div className="pseudo_border"></div>
            <div className="invoice_footer_column_wrapper">
              <div className="invoice_footer_column_cont">
                <img
                  src={require("../../../assets/images/invoice_footer_text_logo.png")}
                  alt=""
                  className="img_div_contain"
                />
              </div>
              <div className="invoice_footer_column_cont main">
                <div>
                  <div>hello@nordicplum.com </div>
                  <div>+370 606 96460</div>
                  <div>nordicplum.com</div>
                </div>
                <div>
                  <div>MB „Nordic Plum“</div>
                  <div>{t("Candial account")}: LT047300010180217642</div>
                  <div>AB „Swedbank“</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!readOnly && (
          <div className="invoice_popup_btn_wrapper">
            <div className="invoice_popup_btn_check_wrap">
              <CheckBox
                isChecked={emailCheck}
                onChange={(e) => setEmailCheck(!emailCheck)}
              />
              <span>{t("Send VAT invoice by email")}</span>
            </div>
            <div className="invoice_popup_btn_chcek_wrap"></div>
            <div className="invoice_popup_btn_container">
              <div>
                <button onClick={closePopUp} className="btn btn-default">
                  {t("Cancel")}
                </button>
              </div>
              <div
                onMouseOver={(e) => {
                  if (email && !isEmail(email)) {
                    floatInfo.subscribe(
                      e,
                      `Nurodytas neteisingas el. pašto adresas`
                    );
                  } else {
                    floatInfo.unsubscribe(e);
                  }
                }}
                onMouseOut={(e) => {
                  if (email && !isEmail(email)) {
                    floatInfo.unsubscribe(e);
                  }
                }}
                onClick={() => {
                  if (email && !isEmail(email)) {
                    return;
                  } else {
                    handlePayButtonClick(emailCheck);
                  }
                }}
              >
                <button
                  disabled={
                    loading ||
                    (email && !isEmail(email) ? true : !details.signature)
                  }
                  className={`flexBtn btn-green`}
                >
                  {t("Save")} {loading ? "..." : ""}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
