import React, { useEffect, useRef, useState } from "react";
// import { BubbleSpinLoader } from "react-css-loaders";
import { Input, Search } from "../../components/UI/FormGenerator/FormGenerator";
import { option } from "../../components/UI/FormGenerator/formTypes";
import { ReactComponent as LongLeft } from "../../assets/images/dir_arrow_left.svg";
import { ReactComponent as LongRight } from "../../assets/images/dir_arrow_right.svg";
import { ReactComponent as EditLogo } from "../../assets/images/edit.svg";
import { ReactComponent as DelIcon } from "../../assets/images/del.svg";
import { ReactComponent as LocaleIcon } from "../../assets/images/locale.svg";
import "./Classifier.css";
import "./loader.css";
import { site } from "../../@types/sites";
import {
  carrierType,
  companyType,
  groupType,
  municipalityType,
  originType,
} from "../WasteAcceptance/@types";
import IconContainer from "../../components/UI/iconContainer/iconContainer";
import NewPopUpFrame from "../../components/UI/PopupFrame/NewPopUpFrame";
import { rearrangeRole } from "../../helpers/misc";
import { wasteActivityTypeType } from "../../@types/waste";
import dropLogo from "../../assets/images/down-arrow.svg";
import DropDown from "../../components/elements/DropDown/DropDown";
import useTranslate from "../../translate/useTranslate";
import { getMode } from "../../settings";
// import { BubbleSpinLoader } from "react-css-loaders";

type classsifierDist =
  | `site`
  | `origin`
  | `municipality`
  | `carrier`
  | `role`
  | `group`
  | `material`
  | `formation`
  | `wasteActivityType`;

const Classifier = ({
  changeLocale,
  locale,
  //Sites
  get_inactive_sites,
  restore_site,
  deactivate_site,
  hide_site,
  create_site,
  edit_site,
  sites,
  inactiveSites,
  //Municipalities
  get_inactive_municipalities,
  restore_municipality,
  deactivate_municipality,
  hide_municipality,
  create_municipality,
  edit_municipality,
  municipalities,
  inactiveMunicipalities,
  //Origins
  get_inactive_origins,
  restore_origin,
  deactivate_origin,
  hide_origin,
  create_origin,
  edit_origin,
  origins,
  inactiveOrigins,
  //Roles
  get_inactive_roles,
  restore_role,
  deactivate_role,
  hide_role,
  create_role,
  edit_role,
  roles,
  inactiveRoles,
  //Carriers
  get_inactive_carriers,
  restore_carrier,
  deactivate_carrier,
  hide_carrier,
  create_carrier,
  edit_carrier,
  carriers,
  inactiveCarriers,
  //Groups
  get_inactive_groups,
  restore_group,
  deactivate_group,
  hide_group,
  create_group,
  edit_group,
  groups,
  inactiveGroups,
  //WasteActivityTypes
  get_inactive_wasteActivityTypes,
  restore_wasteActivityType,
  deactivate_wasteActivityType,
  hide_wasteActivityType,
  create_wasteActivityType,
  edit_wasteActivityType,
  wasteActivityTypes,
  inactiveWasteActivityTypes,
  //Formations
  get_inactive_formations,
  restore_formation,
  deactivate_formation,
  hide_formation,
  create_formation,
  edit_formation,
  formations,
  inactiveFormations,
  //Materials
  get_inactive_materials,
  restore_material,
  deactivate_material,
  hide_material,
  create_material,
  edit_material,
  materials,
  inactiveMaterials,
  // companies
  companies,
}: {
  changeLocale: (value: "ENGLISH" | "LITHUANIAN") => void;
  locale: "ENGLISH" | "LITHUANIAN";
  //Sites
  get_inactive_sites: () => void;
  restore_site: (data: { id: number }) => void;
  deactivate_site: (data: { id: number }) => void;
  hide_site: (data: { id: number }) => void;
  create_site: (data: { name: string }) => void;
  sites: site[];
  inactiveSites: site[];
  edit_site: (data: {
    name: string;
    id: number;
    address?: string;
    companyCode?: string;
    municipalityId?: number;
  }) => void;
  //Municipalities
  get_inactive_municipalities: () => void;
  restore_municipality: (data: { id: number }) => void;
  deactivate_municipality: (data: { id: number }) => void;
  hide_municipality: (data: { id: number }) => void;
  create_municipality: (data: { name: string }) => void;
  municipalities: municipalityType[];
  inactiveMunicipalities: municipalityType[];
  edit_municipality: (data: { name: string; id: number }) => void;
  //Origins
  get_inactive_origins: () => void;
  restore_origin: (data: { id: number }) => void;
  deactivate_origin: (data: { id: number }) => void;
  hide_origin: (data: { id: number }) => void;
  create_origin: (data: { name: string }) => void;
  origins: originType[];
  inactiveOrigins: originType[];
  edit_origin: (data: { name: string; id: number }) => void;
  //Roles
  get_inactive_roles: () => void;
  restore_role: (data: { id: number }) => void;
  deactivate_role: (data: { id: number }) => void;
  hide_role: (data: { id: number }) => void;
  create_role: (data: { name: string }) => void;
  roles: originType[];
  inactiveRoles: originType[];
  edit_role: (data: { name: string; id: number }) => void;
  //Carriers
  get_inactive_carriers: () => void;
  restore_carrier: (data: { id: number }) => void;
  deactivate_carrier: (data: { id: number }) => void;
  hide_carrier: (data: { id: number }) => void;
  create_carrier: (data: { name: string }) => void;
  carriers: carrierType[];
  inactiveCarriers: carrierType[];
  edit_carrier: (data: {
    name: string;
    id: number;
    companyId?: number;
  }) => void;
  //Groups
  get_inactive_groups: () => void;
  restore_group: (data: { id: number }) => void;
  deactivate_group: (data: { id: number }) => void;
  hide_group: (data: { id: number }) => void;
  create_group: (data: { name: string }) => void;
  groups: groupType[];
  inactiveGroups: groupType[];
  edit_group: (data: { name: string; id: number }) => void;
  //WasteActivityTypes
  get_inactive_wasteActivityTypes: () => void;
  restore_wasteActivityType: (data: { id: number }) => void;
  deactivate_wasteActivityType: (data: { id: number }) => void;
  hide_wasteActivityType: (data: { id: number }) => void;
  create_wasteActivityType: (data: { name: string }) => void;
  wasteActivityTypes: wasteActivityTypeType[];
  inactiveWasteActivityTypes: wasteActivityTypeType[];
  edit_wasteActivityType: (data: { name: string; id: number }) => void;
  //Formations
  get_inactive_formations: () => void;
  restore_formation: (data: { id: number }) => void;
  deactivate_formation: (data: { id: number }) => void;
  hide_formation: (data: { id: number }) => void;
  create_formation: (data: { name: string }) => void;
  formations: originType[];
  inactiveFormations: originType[];
  edit_formation: (data: { name: string; id: number }) => void;
  //Materials
  get_inactive_materials: () => void;
  restore_material: (data: { id: number }) => void;
  deactivate_material: (data: { id: number }) => void;
  hide_material: (data: { id: number }) => void;
  create_material: (data: { name: string }) => void;
  materials: originType[];
  inactiveMaterials: originType[];
  edit_material: (data: { name: string; id: number }) => void;
  // companies
  companies: companyType[];
}) => {
  const { t } = useTranslate();
  const [classifierSearch, setClassifierSearch] = useState("");

  const [newSite, setNewSite] = useState("");
  const [newOrigin, setNewOrigin] = useState("");
  const [newMunicipality, setNewMunicipality] = useState("");
  const [newRole, setNewRole] = useState("");
  const [newCarrier, setNewCarrier] = useState("");
  const [newGroup, setNewGroup] = useState("");
  const [newWasteActivityType, setNewWasteActivityType] = useState("");
  const [newMaterial, setNewMaterial] = useState("");
  const [newFormation, setNewFormation] = useState("");

  const [inactiveSiteOptions, setInactiveSiteOptions] = useState<option[]>([]);
  const [inactiveMunicipalityOptions, setInactiveMunicipalityOptions] =
    useState<option[]>([]);
  const [inactiveOriginOptions, setInactiveOriginOptions] = useState<option[]>(
    []
  );
  const [inactiveRoleOptions, setInactiveRoleOptions] = useState<option[]>([]);
  const [inactiveCarrierOptions, setInactiveCarrierOptions] = useState<
    option[]
  >([]);
  const [inactiveGroupOptions, setInactiveGroupOptions] = useState<option[]>(
    []
  );
  const [
    inactiveWasteActivityTypeOptions,
    setInactiveWasteActivityTypeOptions,
  ] = useState<option[]>([]);
  const [inactiveFormationOptions, setInactiveFormationOptions] = useState<
    option[]
  >([]);
  const [inactiveMaterialOptions, setInactiveMaterialOptions] = useState<
    option[]
  >([]);

  const [siteOptions, setSiteOptions] = useState<option[]>([]);
  const [municipalityOptions, setMunicipalityOptions] = useState<option[]>([]);
  const [originOptions, setOriginOptions] = useState<option[]>([]);
  const [roleOptions, setRoleOptions] = useState<option[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<option[]>([]);
  const [groupOptions, setGroupOptions] = useState<option[]>([]);
  const [wasteActivityTypeOptions, setWasteActivityTypeOptions] = useState<
    option[]
  >([]);
  const [formationOptions, setFormationOptions] = useState<option[]>([]);
  const [materialOptions, setMaterialOptions] = useState<option[]>([]);
  const [companyOptions, setCompanyOptions] = useState<option[]>([]);

  const [editObject, setEditObject] = useState<
    option & {
      type: classsifierDist;
      address?: string;
      companyCode?: string;
      municipalityId?: number;
      companyId?: number;
    }
  >();
  const [classifierPopUpVisibility, setClassifierPopUpVisibility] =
    useState(false);
  const defaultOption: option = { label: "", value: 0 };

  useEffect(() => {
    get_inactive_sites();
    get_inactive_municipalities();
    get_inactive_origins();
    get_inactive_roles();
    get_inactive_groups();
    get_inactive_wasteActivityTypes();
    get_inactive_formations();
    get_inactive_materials();
  }, []);

  useEffect(() => {
    if (!classifierPopUpVisibility) {
      setEditObject(undefined);
    }
  }, [classifierPopUpVisibility]);

  useEffect(() => {
    if (sites) {
      const options: option[] = sites.map((site) => ({
        label: site.name,
        value: site.id,
        address: site.address,
        companyCode: site.companyCode,
        municipalityId: site.municipalityId,
      }));
      setSiteOptions(options);
    }
  }, [sites]);

  useEffect(() => {
    if (inactiveSites) {
      const options: option[] = inactiveSites.map((inactive_site) => ({
        label: inactive_site.name,
        value: inactive_site.id,
      }));
      setInactiveSiteOptions(options);
    }
  }, [inactiveSites]);

  useEffect(() => {
    if (municipalities) {
      const options: option[] = municipalities.map((municipality) => ({
        label: municipality.name,
        value: municipality.id,
      }));
      setMunicipalityOptions(options);
    }
  }, [municipalities]);

  useEffect(() => {
    if (inactiveMunicipalities) {
      const options: option[] = inactiveMunicipalities.map(
        (inactive_municipality) => ({
          label: inactive_municipality.name,
          value: inactive_municipality.id,
        })
      );
      setInactiveMunicipalityOptions(options);
    }
  }, [inactiveMunicipalities]);

  useEffect(() => {
    if (origins) {
      const options: option[] = origins.map((origin) => ({
        label: origin.name,
        value: origin.id,
      }));
      setOriginOptions(options);
    }
  }, [origins]);

  useEffect(() => {
    if (inactiveOrigins) {
      const options: option[] = inactiveOrigins.map((inactive_origin) => ({
        label: inactive_origin.name,
        value: inactive_origin.id,
      }));
      setInactiveOriginOptions(options);
    }
  }, [inactiveOrigins]);

  useEffect(() => {
    if (roles) {
      const options: option[] = rearrangeRole(
        roles.map((role) => ({
          label: role.name,
          value: role.id,
        }))
      );
      setRoleOptions(options);
    }
  }, [roles]);

  useEffect(() => {
    if (inactiveOrigins) {
      const options: option[] = inactiveRoles.map((inactive_role) => ({
        label: inactive_role.name,
        value: inactive_role.id,
      }));
      setInactiveRoleOptions(options);
    }
  }, [inactiveRoles]);

  useEffect(() => {
    if (carriers) {
      const options: option[] = carriers.map((carrier) => ({
        label: carrier.name,
        value: carrier.id,
        companyId: carrier.companyId,
      }));
      setCarrierOptions(options);
    }
  }, [carriers]);

  useEffect(() => {
    if (inactiveOrigins) {
      const options: option[] = inactiveCarriers.map((inactive_carrier) => ({
        label: inactive_carrier.name,
        value: inactive_carrier.id,
      }));
      setInactiveCarrierOptions(options);
    }
  }, [inactiveCarriers]);

  useEffect(() => {
    if (groups) {
      const options: option[] = groups.map((group) => ({
        label: group.name,
        value: group.id,
      }));

      setGroupOptions(options);
    }
  }, [groups]);

  useEffect(() => {
    if (inactiveGroups) {
      const options: option[] = inactiveGroups.map((inactive_group) => ({
        label: inactive_group.name,
        value: inactive_group.id,
      }));
      setInactiveGroupOptions(options);
    }
  }, [inactiveGroups]);

  useEffect(() => {
    if (wasteActivityTypes) {
      const options: option[] = wasteActivityTypes.map((wasteActivityType) => ({
        label: wasteActivityType.name,
        value: wasteActivityType.id,
      }));

      setWasteActivityTypeOptions(options);
    }
  }, [wasteActivityTypes]);

  useEffect(() => {
    if (inactiveWasteActivityTypes) {
      const options: option[] = inactiveWasteActivityTypes.map(
        (inactive_wasteActivityType) => ({
          label: inactive_wasteActivityType.name,
          value: inactive_wasteActivityType.id,
        })
      );
      setInactiveWasteActivityTypeOptions(options);
    }
  }, [inactiveWasteActivityTypes]);

  useEffect(() => {
    if (formations) {
      const options: option[] = formations.map((formation) => ({
        label: formation.name,
        value: formation.id,
      }));
      setFormationOptions(options);
    }
  }, [formations]);

  useEffect(() => {
    if (inactiveOrigins) {
      const options: option[] = inactiveFormations.map(
        (inactive_formation) => ({
          label: inactive_formation.name,
          value: inactive_formation.id,
        })
      );
      setInactiveFormationOptions(options);
    }
  }, [inactiveFormations]);

  useEffect(() => {
    if (materials) {
      const options: option[] = materials.map((material) => ({
        label: material.name,
        value: material.id,
      }));
      setMaterialOptions(options);
    }
  }, [materials]);

  useEffect(() => {
    if (inactiveOrigins) {
      const options: option[] = inactiveMaterials.map((inactive_material) => ({
        label: inactive_material.name,
        value: inactive_material.id,
      }));
      setInactiveMaterialOptions(options);
    }
  }, [inactiveMaterials]);

  useEffect(() => {
    if (companies) {
      const options: option[] = companies.map((comp) => ({
        label: comp.companyName,
        value: comp.id,
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  const handleSubmit = (text: string) => {
    setClassifierSearch(text);
  };

  const classifierConstruct: {
    construct: {
      text: string;
      textSetter: React.Dispatch<React.SetStateAction<string>>;
      handler: () => void;
    };
    type: classsifierDist;
    title: string;
    active: option[];
    inactive: option[];
    restore: (data: number) => void;
    deactivate: (data: number) => void;
    hide: (data: number) => void;
  }[] = [
    {
      title: t("Site List"),
      construct: {
        text: newSite,
        textSetter: setNewSite,
        handler: () => {
          if (newSite) {
            create_site({ name: newSite });
          }
        },
      },
      type: `site`,
      active: siteOptions,
      inactive: inactiveSiteOptions,
      restore: (data) => restore_site({ id: data }),
      deactivate: (data) => deactivate_site({ id: data }),
      hide: (data) => hide_site({ id: data }),
    },
    {
      title: t("Origin of waste"),
      construct: {
        text: newOrigin,
        textSetter: setNewOrigin,
        handler: () => {
          if (newOrigin) {
            create_origin({ name: newOrigin });
          }
        },
      },
      type: `origin`,
      active: originOptions,
      inactive: inactiveOriginOptions,
      restore: (data) => restore_origin({ id: data }),
      deactivate: (data) => deactivate_origin({ id: data }),
      hide: (data) => hide_origin({ id: data }),
    },
    {
      title: t("Municipalities of waste generation"),
      construct: {
        text: newMunicipality,
        textSetter: setNewMunicipality,
        handler: () => {
          if (newMunicipality) {
            create_municipality({ name: newMunicipality });
          }
        },
      },
      type: `municipality`,
      active: municipalityOptions,
      inactive: inactiveMunicipalityOptions,
      restore: (data) => restore_municipality({ id: data }),
      deactivate: (data) => deactivate_municipality({ id: data }),
      hide: (data) => hide_municipality({ id: data }),
    },
    {
      title: t("Waste carriers"),
      construct: {
        text: newCarrier,
        textSetter: setNewCarrier,
        handler: () => {
          if (newCarrier) {
            create_carrier({ name: newCarrier });
          }
        },
      },
      type: `carrier`,
      active: carrierOptions,
      inactive: inactiveCarrierOptions,
      restore: (data) => restore_carrier({ id: data }),
      deactivate: (data) => deactivate_carrier({ id: data }),
      hide: (data) => hide_carrier({ id: data }),
    },
    {
      title: t("Employee Type in System (Role)"),
      construct: {
        text: newRole,
        textSetter: setNewRole,
        handler: () => {
          if (newRole) {
            create_role({ name: newRole });
          }
        },
      },
      type: `role`,
      active: roleOptions,
      inactive: inactiveRoleOptions,
      restore: (data) => restore_role({ id: data }),
      deactivate: (data) => deactivate_role({ id: data }),
      hide: (data) => hide_role({ id: data }),
    },
    {
      title: t("Waste Group"),
      construct: {
        text: newGroup,
        textSetter: setNewGroup,
        handler: () => {
          if (newGroup) {
            create_group({ name: newGroup });
          }
        },
      },
      type: `group`,
      active: groupOptions,
      inactive: inactiveGroupOptions,
      restore: (data) => restore_group({ id: data }),
      deactivate: (data) => deactivate_group({ id: data }),
      hide: (data) => hide_group({ id: data }),
    },
    {
      title: t("List of materials used during waste management"),
      construct: {
        text: newMaterial,
        textSetter: setNewMaterial,
        handler: () => {
          if (newMaterial) {
            create_material({ name: newMaterial });
          }
        },
      },
      type: `material`,
      active: materialOptions,
      inactive: inactiveMaterialOptions,
      restore: (data) => restore_material({ id: data }),
      deactivate: (data) => deactivate_material({ id: data }),
      hide: (data) => hide_material({ id: data }),
    },
    {
      title: t("List of (obtained) materials formed during waste management"),
      construct: {
        text: newFormation,
        textSetter: setNewFormation,
        handler: () => {
          if (newFormation) {
            create_formation({ name: newFormation });
          }
        },
      },
      type: `formation`,
      active: formationOptions,
      inactive: inactiveFormationOptions,
      restore: (data) => restore_formation({ id: data }),
      deactivate: (data) => deactivate_formation({ id: data }),
      hide: (data) => hide_formation({ id: data }),
    },
    {
      title: t("List of Waste Management Actions"),
      construct: {
        text: newWasteActivityType,
        textSetter: setNewWasteActivityType,
        handler: () => {
          if (newWasteActivityType) {
            create_wasteActivityType({ name: newWasteActivityType });
          }
        },
      },
      type: `wasteActivityType`,
      active: wasteActivityTypeOptions,
      inactive: inactiveWasteActivityTypeOptions,
      restore: (data) => restore_wasteActivityType({ id: data }),
      deactivate: (data) => deactivate_wasteActivityType({ id: data }),
      hide: (data) => hide_wasteActivityType({ id: data }),
    },
  ];

  const mode = getMode();
  return (
    <div className="classifier">
      {classifierPopUpVisibility && editObject && (
        <NewPopUpFrame
          small={true}
          showShadow={false}
          handleOpen={setClassifierPopUpVisibility}
          isShown={classifierPopUpVisibility}
          title={t("Classifier value")}
        >
          <div className="classifier_popUp">
            <textarea
              placeholder={t("Enter a new classifier value")}
              value={editObject.label}
              onChange={(e) => {
                const value = e.target.value;
                setEditObject({
                  ...editObject,
                  label: value,
                });
              }}
              name=""
              id=""
            ></textarea>
            {editObject.type === "site" && (
              <div>
                <input
                  placeholder={t("Enter the address")}
                  value={editObject.address}
                  onChange={(e) => {
                    const value = e.target.value;
                    setEditObject({
                      ...editObject,
                      address: value,
                    });
                  }}
                  name=""
                  id=""
                  style={{ marginTop: "1em", marginBottom: "1em" }}
                />
                <DropDown
                  options={municipalityOptions}
                  value={
                    municipalityOptions.find(
                      (x) => x.value === editObject.municipalityId
                    ) || defaultOption
                  }
                  onSelect={(e) => {
                    const value = e.value;
                    setEditObject({
                      ...editObject,
                      municipalityId: value,
                    });
                  }}
                  title={t("Municipality")}
                  border={""}
                  error={false}
                />
                <input
                  placeholder={t("Enter the company code")}
                  value={editObject.companyCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    setEditObject({
                      ...editObject,
                      companyCode: value,
                    });
                  }}
                  name=""
                  id=""
                  style={{ marginTop: "1em" }}
                />
              </div>
            )}
            {editObject.type === "carrier" && (
              <div style={{ marginTop: "1em" }}>
                <DropDown
                  options={companyOptions}
                  value={
                    companyOptions.find(
                      (x) => x.value === editObject.companyId
                    ) || defaultOption
                  }
                  onSelect={(e) => {
                    const value = e.value;
                    setEditObject({
                      ...editObject,
                      companyId: value,
                    });
                  }}
                  title={t("Associated company")}
                  border={""}
                  error={false}
                />
              </div>
            )}
            <div className="classifierPopUpButton_wrapper">
              <button
                onClick={() => setClassifierPopUpVisibility(false)}
                className="btn btn-default"
              >
                {t("Cancel")}
              </button>
              <button
                disabled={
                  (editObject.type === "site" &&
                    (!editObject.label ||
                      !editObject.value ||
                      !editObject.address ||
                      !editObject.companyCode ||
                      !editObject.municipalityId)) ||
                  !editObject.label ||
                  !editObject.value
                }
                onClick={() => {
                  if (editObject.type === "site") {
                    edit_site({
                      id: editObject.value,
                      name: editObject.label,
                      address: editObject.address,
                      companyCode: editObject.companyCode,
                      municipalityId: editObject.municipalityId,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "origin") {
                    edit_origin({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "municipality") {
                    edit_municipality({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "role") {
                    edit_role({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "carrier") {
                    edit_carrier({
                      id: editObject.value,
                      name: editObject.label,
                      companyId: editObject.companyId,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "group") {
                    edit_group({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "wasteActivityType") {
                    edit_wasteActivityType({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "material") {
                    edit_material({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "formation") {
                    edit_formation({
                      id: editObject.value,
                      name: editObject.label,
                    });
                    setClassifierPopUpVisibility(false);
                  } else if (editObject.type === "wasteActivityType") {
                    setClassifierPopUpVisibility(false);
                  }
                }}
                className="btn btn-green"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </NewPopUpFrame>
      )}
      <div className="report_search_wrapper">
        <p>{t("CHOOSE A CLASSIFIER")}</p>
        <Search
          externalValue={classifierSearch}
          setExternalValue={handleSubmit}
          search={handleSubmit}
          placeholder={t("Search")}
        />
      </div>
      <div className="change_locale_wrapper">
        <div className="changle_locale_icon_wrapper center">
          <LocaleIcon style={{ pointerEvents: "none" }} />
        </div>
        <div className="change_locale_btn_wrapper">
          <div
            onClick={() => {
              changeLocale("ENGLISH");
            }}
            className={`change_locale_btn ${
              locale === "ENGLISH" ? "active" : "inactive"
            }`}
          >
            en
          </div>
          <div
            onClick={() => {
              changeLocale("LITHUANIAN");
            }}
            className={`change_locale_btn ${
              locale === "ENGLISH" ? "inactive" : "active"
            }`}
          >
            lt
          </div>
        </div>
      </div>
      {classifierConstruct.map(
        (constructObj, index) =>
          constructObj.title
            .toLowerCase()
            .includes(classifierSearch.toLowerCase()) && (
            <ClassifierTable
              pageConstruct={constructObj.construct}
              title={constructObj.title}
              active={constructObj.active}
              inactive={constructObj.inactive}
              restore={constructObj.restore}
              setEditObject={setEditObject}
              setClassifierPopUpVisibility={setClassifierPopUpVisibility}
              key={`ClassifierTable_${index}`}
              type={constructObj.type}
              deactivate={constructObj.deactivate}
              hide={constructObj.hide}
            />
          )
      )}
    </div>
  );
};

export default Classifier;

const ClassifierTable = ({
  pageConstruct,
  title,
  active,
  inactive,
  restore,
  deactivate,
  hide,
  type,
  setClassifierPopUpVisibility,
  setEditObject,
}: {
  pageConstruct: {
    text: string;
    textSetter: React.Dispatch<React.SetStateAction<string>>;
    handler: () => void;
  };
  title: string;
  active: option[];
  inactive: option[];
  restore: (data: number) => void;
  deactivate: (data: number) => void;
  hide: (data: number) => void;
  type: classsifierDist;
  setClassifierPopUpVisibility: (viisible: boolean) => void;
  setEditObject: (
    data: option & {
      type: classsifierDist;
      address?: string;
      companyCode?: string;
    }
  ) => void;
}) => {
  const { t } = useTranslate();
  const [activeEllipsis, setActiveEllipsis] = useState<boolean[]>(
    Array(active.length).fill(false)
  );
  const [inactiveEllipsis, setInactiveEllipsis] = useState<boolean[]>(
    Array(inactive.length).fill(false)
  );

  const [spinner, setSpinner] = useState(false);
  const [transferActive, setTransferActive] = useState<number>();
  const [transferInactive, setTransferInactive] = useState<number>();

  const transfer = (value: number | undefined, deactivate: boolean) => {
    if (deactivate) {
      if (!spinner) {
        setTransferActive(value);
        setTransferInactive(undefined);
      }
    } else {
      if (!spinner) {
        setTransferInactive(value);
        setTransferActive(undefined);
      }
    }
  };

  useEffect(() => {
    setSpinner(false);
  }, [active, inactive]);

  useEffect(() => {
    setActiveEllipsis(Array(active.length).fill(false));
  }, [active]);
  useEffect(() => {
    setInactiveEllipsis(Array(inactive.length).fill(false));
  }, [inactive]);

  return (
    <div className="classifier_wrapper">
      <div className="classifier_title"></div>
      <div className="classifier_table">
        <div>
          <p>{title}</p>
          <div className="classifier_table_create">
            <Input
              placeholder={t("Enter a new classifier value")}
              type="text"
              value={pageConstruct.text}
              externalValue={pageConstruct.text}
              setExternalValue={pageConstruct.textSetter}
              error={false}
              handler={() => {}}
            />

            <div>
              <button
                disabled={!pageConstruct.text}
                onClick={() => {
                  pageConstruct.handler();
                  pageConstruct.textSetter(``);
                }}
                className="btn btn-green"
              >
                {t("Add")}
              </button>
            </div>
          </div>
        </div>
        <div className=""></div>
        <div className=""></div>
        {/* Title start */}
        <div className="classifier_label">{t("Active classifier values")}</div>
        <div className=""></div>
        <div className="classifier_label">
          {t("Inactive classifier values")}
        </div>
        {/* Title end */}

        {/* Column start */}
        <div className="classifier_column">
          {active.map((item, index) => (
            <div
              key={`column_classifier_${index}`}
              onClick={() => {
                if (transferActive === item.value) {
                  transfer(undefined, true);
                } else {
                  transfer(item.value, true);
                }
              }}
              className={`classifier_item ${
                activeEllipsis[index] ? `` : `ellipsis`
              } ${transferActive === item.value ? `activated` : ``}`}
            >
              <span
                onClick={() => {
                  const newEllipsis = activeEllipsis.map((el, n) => {
                    if (index === n) {
                      return !el;
                    } else {
                      return el;
                    }
                  });
                  setActiveEllipsis(newEllipsis);
                }}
                className={`${activeEllipsis[index] ? `` : `ellipsis`}`}
              >
                {item.label}
              </span>
              <IconContainer>
                <EditLogo
                  onClick={(e) => {
                    setEditObject({
                      ...item,
                      type,
                    });
                    setClassifierPopUpVisibility(true);
                    e.stopPropagation();
                  }}
                />
              </IconContainer>
            </div>
          ))}
        </div>
        {/* Column end */}
        <div className="classifier_column mid">
          {spinner ? (
            <IconContainer>
              <div
                className="sc-htpNat loadingRing"
                color="var(--plum-waste-pry-color)"
              ></div>
            </IconContainer>
          ) : (
            <>
              <div
                className={`classifier_arrow ${
                  transferActive ? `` : `inactive`
                }`}
              >
                <LongRight
                  onClick={() => {
                    if (transferActive) {
                      deactivate(transferActive);
                      setSpinner(true);
                      setTransferActive(undefined);
                    }
                  }}
                />
              </div>
              <div
                className={`classifier_arrow ${
                  transferInactive ? `` : `inactive`
                }`}
              >
                <LongLeft
                  onClick={() => {
                    if (transferInactive) {
                      restore(transferInactive);
                      setSpinner(true);
                      setTransferInactive(undefined);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        {/* Column start */}
        <div className="classifier_column">
          {inactive.map((item, index) => (
            <div
              key={item.value}
              onClick={() => {
                if (item.value === transferInactive) {
                  transfer(undefined, false);
                } else {
                  transfer(item.value, false);
                }
              }}
              className={`classifier_item ${
                inactiveEllipsis[index] ? `noEllipsis` : `ellipsis`
              } ${transferInactive === item.value ? `activated` : ``}`}
            >
              <span
                onClick={() => {
                  const newEllipsis = inactiveEllipsis.map((el, n) => {
                    if (index === n) {
                      return !el;
                    } else {
                      return el;
                    }
                  });
                  setInactiveEllipsis(newEllipsis);
                }}
                className={`${inactiveEllipsis[index] ? `` : `ellipsis`}`}
              >
                {item.label}
              </span>
              <IconContainer size="lg">
                <DelIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setTransferInactive(undefined);
                    hide(item.value);
                  }}
                />
              </IconContainer>
            </div>
          ))}
        </div>
        {/* Column end */}
      </div>
    </div>
  );
};
